@import '@/assets/styles/global.scss';

.Header {
  background-color: var(--bg-header-color);
  min-height: 100px;
  display: flex;
  align-items: center;

  @include mobile {
    min-height: 70px;
  }

  @include xmobile {
    flex-wrap: wrap;
    justify-content: center;
  }

  &-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1440px;
    padding: 0 40px;
    margin: 0 auto;

    @include rwd(1400) {
      padding: 0 15px;
    }
  }

  &-right {
    margin-left: auto;
    min-width: 170px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    justify-content: flex-end;
    @include xmobile {
      margin-left: 0;
    }
  }

  &-icon {
    margin-left: 5px;
  }

  &-lang {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-color-300);
    display: flex;
    align-items: center;
  }

  &-theme {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--gray-color-300);
    display: flex;
    align-items: center;
  }

  &-logo {
    max-width: 124px;
  }

  &-notification {
    padding: 0;
  }

  &-logout {
    display: inline-flex;
    align-items: center;
    color: var(--white-color);
    padding: 0;
    margin-left: 20px;

    svg {
      margin-right: 6px;
    }

    &:hover {
      color: var(--yellow-color);

      svg {
        path {
          stroke: var(--yellow-color)
        }
      }
    }
  }
}
