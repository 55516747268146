@import '@/assets/styles/global.scss';

.Menu {
  $self: &;

  &-list {
    @include tablet {
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-radius: 24px;
      border: 1px solid var(--gray-color-100);
      padding: 20px 30px;
    }
  }

  &-item {
    margin-bottom: 10px;

    @include tablet {
      margin: 0;
    }

    &:last-child {
      margin: 0;
    }
  }

  &-link {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--gray-color);
    padding: 12px 18px 12px 33px;
    @extend %transition;
    border-radius: 8px;

    @include desktop {
      padding: 12px;
    }

    @include tablet {
      padding: 0;
    }

    &:hover,
    &_active {
      background-color: var(--primary-color);
      color: var(--white-color);

      @include tablet {
        background-color: transparent;
      }

      #{$self} {
        &-icon {
          path {
            fill: var(--white-color);
            @include tablet {
              fill: var(--primary-color);
            }
          }
        }

        &-arrow {
          opacity: 1;
        }
      }
    }
  }

  &-icon {
    margin-right: 18px;
    @include desktop {
      margin-right: 8px;
    }

    path {
      fill: var(--gray-color);
      @extend %transition;
    }
  }

  &-arrow {
    @extend %transition;
    opacity: 0;
    margin-left: auto;

    path {
      fill: none;
    }
  }
}
