.Card {
  border: 1px solid var(--gray-color-100);
  border-radius: 5px;
  filter: drop-shadow(0px 15px 90px var(--black-color-100));

  &_reset {
    border: none;
    filter: none;
  }
}
