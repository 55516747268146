// Main color

@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root {
  --white-color: #fff;
  --black-color: #000;
  --black-color-100: rgba(0, 0, 0, 0.01);
  --black-color-200: #16161e;
  --primary-color: #1A1AFF;
  --gray-color-900: #171923;
  --dark-color: #1a202c;
  --red-color: #e53e3e;
  --red-color-100: #e24a4a;
  --bg-header-color: #02021E;
  --button-hover-bg: #080F1E;
  --button-hover-yellow_bg: #FFD44B;
  --green-color: #2bc97c;
  --green-color-100: #38a169;
  --green-color-200: #25855a;
  --yellow-color: #ffc107;
  --blue-color: rgba(74, 135, 226, 0.2);
  --blue-color-100: #1c6fbb;
  --blue-color-200: rgba(52, 153, 230, 0.1);

  --input-border: #cbd5e0;
  --input-border-focus: #5244f3;

  --button-primary-bg: #5244f3;
  --button-gray-bg: #edf2f7;
  --button-active-login-color: #e2edff;
  --button-active-login-bg: #004ac1;

  --gray-color: #617686;
  --gray-color-100: #f1f1f1;
  --gray-color-200: #f6f6f9;
  --gray-color-300: #e2e2e2;
  --gray-color-400: #9a9aaf;
  --gray-color-500: #909090;
  --gray-color-600: #7b7f90;
  --gray-color-700: #718096;

  --loading-circle-one: #5244f3;
  --loading-circle-two: rgba(26, 130, 255, 0.4);
  --loading-circle-three: rgba(26, 130, 255, 0.2);
  --loading-circle-four: rgba(26, 130, 255, 0.1);

  --body-background: #fff;
  --body-color: #1e1f26;
}

// Typography
// Font, line-height, and color for body text, headings, and more.
$default-font: 'Ubuntu', sans-serif !default;
$font-family-base: $default-font !default;

$font-size-base: 16px !default;
$font-size-xsm: 10px !default;
$font-size-sm: 12px !default;
$font-size-md: 14px !default;
$font-size-lg: 18px !default;
$font-size-xlg: 20px !default;
$font-size-required-before: 23.1361px !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 100 !default;
$font-weight-200: 200 !default;
$font-weight-300: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-600: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;
$font-weight-800: 800 !default;
$font-weight-900: 900 !default;

$font-weight-base: $font-weight-normal !default;

$line-height-base: 1.225 !default;
$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;

$border-width: 2px !default;
$border-color: var(--white) !default;

$border-radius: 3px !default;
$border-radius-sm: 5px !default;
$border-radius-md: 8px !default;
$border-radius-lg: 12px !default;
$border-radius-xlg: 25px !default;

$gap: 15px;
