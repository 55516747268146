@import '@/assets/styles/global.scss';

.Login {
  &-switch {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }

  &-btn {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: var(--body-color);
    padding: 10px;
    border: 1px solid var(--gray-color-100);
    width: 100%;
    border-radius: 3px;

    &_active {
      color: var(--primary-color);
      border: 1px solid var(--primary-color);
      // &:first-child {
      //   border-right: 1px solid var(--gray-color-100);
      // }
      // &:last-child {
      //   border-left: 1px solid var(--gray-color-100);
      // }
    }
  }

  &-card {
    width: 100%;
    max-width: 400px;
    padding: 50px 30px;

    @include mobile {
      padding: 50px 15px;
      max-width: 100%;
    }
  }

  &-form {
    margin-top: 40px;
  }

  &-bottom {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }

  &-link {
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-color-100);
  }

  &-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--body-color);
    margin-bottom: 10px;
  }

  &-verify {
    &_bottom {
      margin-top: 20px;
    }
  }
}
