@import '@/assets/styles/global.scss';

.Field {
  position: relative;
  $self: &;

  &_xxl {
    margin-bottom: 40px;
  }
  &_xl {
    margin-bottom: 30px;
  }
  &_lg {
    margin-bottom: 25px;
  }
  &_md {
    margin-bottom: 20px;
  }
  &_sm {
    margin-bottom: 14px;
  }

  &_tel,
  &_none {
    margin-bottom: 0;
  }

  &-wrap {
    position: relative;
  }

  &-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
    z-index: 50;
    &_left {
      left: 16px;
      min-width: 18px;
    }
    &_right {
      right: 16px;
    }
  }

  &-label {
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--black-color);
    display: block;
  }

  &-input {
    width: 100%;
    background-color: var(--white-color);
    border: 1px solid var(--gray-color-100);
    font-weight: 400;
    font-size: 14px;
    // font-size: 18px;
    line-height: 20px;
    color: var(--gray-color-900);
    padding: 5px 16px;
    @extend %transition;
    border-radius: 3px;

    &::placeholder {
      color: var(--gray-color-400);
    }

    // &[readonly] {
    //   &:focus {
    //     border-color: $light-gray;
    //   }
    // }

    // &[disabled] {
    //   background: var(--gray-200-bg);
    //   color: var(--gray-200);
    //   box-shadow: none;
    // }

    &:focus:not(.Field-input_errors) {
      border: 1px solid var(--input-border-focus);
    }

    &_left {
      padding-left: 43px;
    }
    &_right {
      padding-right: 43px;
    }

    &_arrow {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type='number'] {
        -moz-appearance: textfield;
      }
    }

    &_xxl {
      height: 46px;
    }

    &_xl {
      height: 42px;
    }

    &_lg {
      height: 36px;
    }

    &_md {
      height: 30px;
    }

    &_sm {
      height: 28px;
    }

    &_errors {
      border: 1px solid var(--red-color);
    }

    // &_ios {
    //   font-size: 16px;
    //   line-height: 1.2;
    // }

    // &_tel {
    //   padding-left: 100px;
    // }

    &_tel {
      padding-left: 100px;
    }

    @include widescreen {
      font-size: 16px;
    }
  }

  &-eye {
    position: absolute;
    top: 50%;
    right: 18px;
    display: flex;
    padding: 0;
    transform: translateY(-50%);
  }

  &-error {
    margin-top: 5px;
    transition: all 0.5s ease;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.2;
    display: flex;
    align-items: center;
    position: absolute;
    color: var(--red-color);

    // svg {
    //   margin-right: 10px;
    // }

    &.entering,
    &.entered {
      animation: slideError 0.5s ease-out forwards;
    }

    &.exiting {
      animation: slideError 0.5s ease-in alternate-reverse;
    }

    &.exited {
      opacity: 0;
    }
  }

  &-select {
    position: absolute;
    left: 8px;
    top: 8px;
  }
}

@keyframes slideError {
  0% {
    transform: translateX(30px);

    opacity: 0;
  }

  100% {
    transform: translateX(0);

    opacity: 1;
  }
}
