@import '@/assets/styles/global.scss';

.Order {
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-wrap {
    margin-top: 30px;
    border: 1px solid var(--gray-color-100);
    flex-wrap: wrap;
    margin-bottom: 20px;
    display: flex;
  }

  &-block {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 18px 25px;
    border-bottom: 1px solid var(--gray-color-100);

    // &:first-child,
    // &:nth-child(2) {

    // }
  }

  &-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-color);
  }

  &-value {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: var(--dark-color);
    word-break: break-all;
  }

  &-card {
    height: 200px;
  }

  &-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-color);
    @include widescreen {
      width: 100%;
    }
  }

  &-loading {
    width: 100%;
    min-height: 300px;
  }

  &-action {
    margin-top: 30px;
  }

  &-submit {
    max-width: 261px;
    width: 100%;
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
  }

  &-left {
    width: 100%;
    max-width: calc(50% - 15px);
    padding: 32px 28px;
    border-radius: 6px;
    border: 1px solid #f1f1f1;
    background: #fff;
    box-shadow: 0px 10px 20px 0px rgba(5, 4, 7, 0.1);
  }
  &-right {
    width: 100%;
    max-width: calc(50% - 15px);
    color: #a4acb7;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &-img {
    margin-bottom: 30px;
  }

  &-input {
    // border-bottom: 1px solid rgba(#1e1f26, 0.1);
    width: 100%;
    color: #a4acb7;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    padding: 0;
    padding-bottom: 3px;
    color: #1e1f26;
    &::placeholder {
      color: rgba(#a4acb7, 0.3);
    }
  }

  &-field {
    position: relative;
  }

  &-error {
    transition: all 0.5s ease;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.2;
    color: var(--black-color);
    display: flex;
    align-items: center;
    position: absolute;
    color: var(--red-color);
  }
}
