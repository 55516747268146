@import '@/assets/styles/global.scss';

.Transaction {
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  &-wrap {
    margin-top: 30px;
    border: 1px solid var(--gray-color-100);
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  &-block {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 15px 25px;
    border-bottom: 1px solid var(--gray-color-100);
    @include xmobile {
      width: 100%;
    }
  }

  &-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-color);
  }
  &-descTransaction {
    color: var(--gray-color);
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 20px;
  }

}
