@import '@/assets/styles/global.scss';

.Footer {
  padding: 30px 0 85px;
  color: var(--gray-color-600);
  border-top: 1px solid var(--gray-color-100);
  margin-top: auto;

  @include rwd(1400) {
    padding: 30px 0 50px;
  }

  @include tablet {
    padding-bottom: 120px;
  }

  &-line {
    display: flex;
    // @include widescreen {
    //   flex-direction: column;
    // }

    width: 100%;
    max-width: 1440px;
    padding: 0 40px;
    margin: 0 auto;

    @include rwd(1400) {
      padding: 0 15px;
    }

    @include widescreen {
      flex-direction: column;
    }
  }

  &-left {
    max-width: 190px;
    width: 100%;
    padding: 0 30px 40px 0;
    @include rwd(1400) {
      padding: 0 15px 30px 0;
    }
  }

  &-center {
    width: 100%;
    max-width: calc(100% - 190px - 420px);
    padding: 0 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;

    @include rwd(1400) {
      padding: 0 15px;
      max-width: calc(100% - 190px - 390px);
      gap: 10px;
    }

    @include notebook {
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }

    @include desktop {
      max-width: calc(100% - 390px);
    }

    @include widescreen {
      max-width: 100%;
    }

    @include xmobile {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px 10px;
      padding: 0;
    }
  }

  &-right {
    max-width: 420px;
    width: 100%;
    padding-left: 15px;

    @include rwd(1400) {
      max-width: 390px;
    }

    @include widescreen {
      margin-top: 30px;
      max-width: 100%;
      padding: 0;
    }
  }

  &-link {
    font-weight: 400;
    color: var(--gray-color-600);
    text-decoration: underline;
    line-height: 18px;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
  }

  &-header {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: var(--black-color);
    margin-bottom: 10px;
  }

  &-text {
    font-size: 12px;
    font-weight: 400;
    &_bottom {
      margin-bottom: 15px;
    }
  }
  &-icons-payment {
    margin-top: 30px;
    display: flex;
    gap: 10px;
  }

  &-icons-payment img {
      height: 25px;
      width: auto;
      margin-right: 30px;

      &:first-child {
        filter: invert(1);
    }

  }

}
